/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/monitoring/stock/periodic-report/per-warehouse-product";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix + '/index');
    },

    queryData(data) {
        return appApiClient().post(pageUriPrefix + '/query', data);
    },

}
